/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.4-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EventDto,
  EventTimeDto,
  ParticipantDto,
  ProcessAliveResponseDto,
} from '../models';
import {
    EventDtoFromJSON,
    EventTimeDtoFromJSON,
    ParticipantDtoFromJSON,
    ProcessAliveResponseDtoFromJSON,
} from '../models';

export interface AcceptTermsRequest {
    uriId: string;
}

export interface GetEventsRequest {
    ignoreAdminFilter?: boolean;
}

export interface GetFutureEventTimesRequest {
    uriId: string;
}

export interface GetMySelfRequest {
    uriId: string;
}

export interface GetOneByIdRequest {
    id: number;
}

export interface GetOneByUriRequest {
    uriId: string;
}

export interface UnsubscribeRequest {
    codedLink: string;
}

/**
 * 
 */
export class EventControllerApi extends runtime.BaseAPI {

    /**
     * Marks terms & conditions \'accepted\' by participant.
     */
    async acceptTermsRaw(requestParameters: AcceptTermsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uriId === null || requestParameters.uriId === undefined) {
            throw new runtime.RequiredError('uriId','Required parameter requestParameters.uriId was null or undefined when calling acceptTerms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/uri/{uriId}/acceptTerms`.replace(`{${"uriId"}}`, encodeURIComponent(String(requestParameters.uriId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Marks terms & conditions \'accepted\' by participant.
     */
    async acceptTerms(requestParameters: AcceptTermsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptTermsRaw(requestParameters, initOverrides);
    }

    /**
     * Get a list of all events
     */
    async getEventsRaw(requestParameters: GetEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EventDto>>> {
        const queryParameters: any = {};

        if (requestParameters.ignoreAdminFilter !== undefined) {
            queryParameters['ignoreAdminFilter'] = requestParameters.ignoreAdminFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventDtoFromJSON));
    }

    /**
     * Get a list of all events
     */
    async getEvents(requestParameters: GetEventsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EventDto>> {
        const response = await this.getEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all future event time slots for the given event.
     */
    async getFutureEventTimesRaw(requestParameters: GetFutureEventTimesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EventTimeDto>>> {
        if (requestParameters.uriId === null || requestParameters.uriId === undefined) {
            throw new runtime.RequiredError('uriId','Required parameter requestParameters.uriId was null or undefined when calling getFutureEventTimes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/uri/{uriId}/times`.replace(`{${"uriId"}}`, encodeURIComponent(String(requestParameters.uriId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventTimeDtoFromJSON));
    }

    /**
     * Returns all future event time slots for the given event.
     */
    async getFutureEventTimes(requestParameters: GetFutureEventTimesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EventTimeDto>> {
        const response = await this.getFutureEventTimesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the current user\'s participant object.
     */
    async getMySelfRaw(requestParameters: GetMySelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantDto>> {
        if (requestParameters.uriId === null || requestParameters.uriId === undefined) {
            throw new runtime.RequiredError('uriId','Required parameter requestParameters.uriId was null or undefined when calling getMySelf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/uri/{uriId}/me`.replace(`{${"uriId"}}`, encodeURIComponent(String(requestParameters.uriId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantDtoFromJSON(jsonValue));
    }

    /**
     * Returns the current user\'s participant object.
     */
    async getMySelf(requestParameters: GetMySelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantDto> {
        const response = await this.getMySelfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get element by PK
     */
    async getOneByIdRaw(requestParameters: GetOneByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOneById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/id/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventDtoFromJSON(jsonValue));
    }

    /**
     * Get element by PK
     */
    async getOneById(requestParameters: GetOneByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventDto> {
        const response = await this.getOneByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get element by uriid
     */
    async getOneByUriRaw(requestParameters: GetOneByUriRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventDto>> {
        if (requestParameters.uriId === null || requestParameters.uriId === undefined) {
            throw new runtime.RequiredError('uriId','Required parameter requestParameters.uriId was null or undefined when calling getOneByUri.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/uri/{uriId}`.replace(`{${"uriId"}}`, encodeURIComponent(String(requestParameters.uriId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventDtoFromJSON(jsonValue));
    }

    /**
     * Get element by uriid
     */
    async getOneByUri(requestParameters: GetOneByUriRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventDto> {
        const response = await this.getOneByUriRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process alive ping
     */
    async processAliveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessAliveResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/processAlive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessAliveResponseDtoFromJSON(jsonValue));
    }

    /**
     * Process alive ping
     */
    async processAlive(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessAliveResponseDto> {
        const response = await this.processAliveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Unsubscribe from an event identified by a coded URL.
     */
    async unsubscribeRaw(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.codedLink === null || requestParameters.codedLink === undefined) {
            throw new runtime.RequiredError('codedLink','Required parameter requestParameters.codedLink was null or undefined when calling unsubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/unsubscribe/{codedLink}`.replace(`{${"codedLink"}}`, encodeURIComponent(String(requestParameters.codedLink))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unsubscribe from an event identified by a coded URL.
     */
    async unsubscribe(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unsubscribeRaw(requestParameters, initOverrides);
    }

}
