/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.4-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventTimeDto } from './EventTimeDto';
import {
    EventTimeDtoFromJSON,
    EventTimeDtoFromJSONTyped,
    EventTimeDtoToJSON,
} from './EventTimeDto';

/**
 * 
 * @export
 * @interface EventDto
 */
export interface EventDto {
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    uriId?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    imageSrcSet?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    eventTypeHeadline?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    imageAspect?: EventDtoImageAspectEnum;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    ndaText?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    streamUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    posterUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    chatEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    prerollUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    prerollAutoplayEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    prerollLoopEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    prerollSoundEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    oneWayChat?: boolean;
    /**
     * 
     * @type {Array<EventTimeDto>}
     * @memberof EventDto
     */
    eventTimes?: Array<EventTimeDto>;
}


/**
 * @export
 */
export const EventDtoImageAspectEnum = {
    A169: 'A16_9',
    A916: 'A9_16',
    A43: 'A4_3',
    A34: 'A3_4',
    A11: 'A1_1',
    A106: 'A10_6',
    A65: 'A6_5',
    A21: 'A2_1',
    A23: 'A2_3',
    A45: 'A4_5',
    A32: 'A3_2'
} as const;
export type EventDtoImageAspectEnum = typeof EventDtoImageAspectEnum[keyof typeof EventDtoImageAspectEnum];


/**
 * Check if a given object implements the EventDto interface.
 */
export function instanceOfEventDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventDtoFromJSON(json: any): EventDto {
    return EventDtoFromJSONTyped(json, false);
}

export function EventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uriId': !exists(json, 'uriId') ? undefined : json['uriId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'imageSrcSet': !exists(json, 'imageSrcSet') ? undefined : json['imageSrcSet'],
        'eventTypeHeadline': !exists(json, 'eventTypeHeadline') ? undefined : json['eventTypeHeadline'],
        'imageAspect': !exists(json, 'imageAspect') ? undefined : json['imageAspect'],
        'ndaText': !exists(json, 'ndaText') ? undefined : json['ndaText'],
        'streamUrl': !exists(json, 'streamUrl') ? undefined : json['streamUrl'],
        'posterUrl': !exists(json, 'posterUrl') ? undefined : json['posterUrl'],
        'chatEnabled': !exists(json, 'chatEnabled') ? undefined : json['chatEnabled'],
        'prerollUrl': !exists(json, 'prerollUrl') ? undefined : json['prerollUrl'],
        'prerollAutoplayEnabled': !exists(json, 'prerollAutoplayEnabled') ? undefined : json['prerollAutoplayEnabled'],
        'prerollLoopEnabled': !exists(json, 'prerollLoopEnabled') ? undefined : json['prerollLoopEnabled'],
        'prerollSoundEnabled': !exists(json, 'prerollSoundEnabled') ? undefined : json['prerollSoundEnabled'],
        'oneWayChat': !exists(json, 'oneWayChat') ? undefined : json['oneWayChat'],
        'eventTimes': !exists(json, 'eventTimes') ? undefined : ((json['eventTimes'] as Array<any>).map(EventTimeDtoFromJSON)),
    };
}

export function EventDtoToJSON(value?: EventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'uriId': value.uriId,
        'description': value.description,
        'imageSrcSet': value.imageSrcSet,
        'eventTypeHeadline': value.eventTypeHeadline,
        'imageAspect': value.imageAspect,
        'ndaText': value.ndaText,
        'streamUrl': value.streamUrl,
        'posterUrl': value.posterUrl,
        'chatEnabled': value.chatEnabled,
        'prerollUrl': value.prerollUrl,
        'prerollAutoplayEnabled': value.prerollAutoplayEnabled,
        'prerollLoopEnabled': value.prerollLoopEnabled,
        'prerollSoundEnabled': value.prerollSoundEnabled,
        'oneWayChat': value.oneWayChat,
        'eventTimes': value.eventTimes === undefined ? undefined : ((value.eventTimes as Array<any>).map(EventTimeDtoToJSON)),
    };
}

