import {
    Spinner,
    ThemeProvider
} from "@volkswagen-onehub/components-core";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/respimg/ls.respimg";
import log from "loglevel";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./fonts/vwhead-bold.woff";
import "./fonts/vwhead-bold.woff2";
import "./fonts/vwhead-bolditalic.woff";
import "./fonts/vwhead-bolditalic.woff2";
import "./fonts/vwhead-extrabold.woff";
import "./fonts/vwhead-extrabold.woff2";
import "./fonts/vwhead-light.woff";
import "./fonts/vwhead-light.woff2";
import "./fonts/vwhead-regular.woff";
import "./fonts/vwhead-regular.woff2";
import "./fonts/vwhead-regularitalic.woff";
import "./fonts/vwhead-regularitalic.woff2";
import "./fonts/vwtext-bold.woff";
import "./fonts/vwtext-bold.woff2";
import "./fonts/vwtext-bolditalic.woff";
import "./fonts/vwtext-bolditalic.woff2";
import "./fonts/vwtext-light.woff";
import "./fonts/vwtext-light.woff2";
import "./fonts/vwtext-regular.woff";
import "./fonts/vwtext-regular.woff2";
import "./fonts/vwtext-regularitalic.woff";
import "./fonts/vwtext-regularitalic.woff2";

import styled from "styled-components";
import { setupInitialConfiguration } from "./ApplicationContext";
import "./fonts.css";
import "./i18n";
import "./index.scss";

const SpinerWrap = styled.div`
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

log.setDefaultLevel(process.env.NODE_ENV === "development" ? log.levels.DEBUG : log.levels.INFO);

// initializing configuration
setupInitialConfiguration();

ReactDOM.render(
    <ThemeProvider>
        <Suspense fallback={<SpinerWrap><Spinner variant="x-large" /></SpinerWrap>}>
            <App />
        </Suspense>
    </ThemeProvider>
    , document.getElementById("root"));

